import React from "react";
import { Box, useTheme, Typography } from "@material-ui/core";
import ProductsCard, { ProductCardItemType } from "../ProductsCard";
import { formatPrice } from "../../../helpers/price";
import { SpacingProps } from "@material-ui/system";
import useTranslate from "../../../hooks/use-translate";
import { PriceType } from "../../../types/airgraft";
import { Alert, Skeleton } from "@material-ui/lab";

const PriceSkeleton = () => (
  <Skeleton
    style={{ minWidth: "80px", borderRadius: "4px" }}
    variant="rect"
    animation="wave"
  />
);

type Props = {
  productCardItems?: ProductCardItemType[];
  deliveryFeeTotal?: PriceType;
  discountTotal?: PriceType;
  subTotal?: PriceType;
  total?: PriceType;
  totalSuffix?: string;
  taxTotal?: PriceType;
  previewLoading?: boolean;
} & SpacingProps;

export default function ExpressDeliveryOrderSummary({
  productCardItems: productItems,
  deliveryFeeTotal,
  discountTotal,
  taxTotal,
  subTotal,
  totalSuffix,
  total,
  previewLoading,
  ...boxProps
}: Props) {
  const theme = useTheme();
  const t = useTranslate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      width="100%"
      {...boxProps}
    >
      {productItems && productItems.length > 0 && (
        <ProductsCard products={productItems} marginBottom={4} />
      )}

      <Box
        display="flex"
        borderTop={`2px solid ${theme.palette.grey[400]}`}
        justifyContent="flex-end"
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginTop={{ xs: 3, md: 3 }}
        >
          {/* Subtotal */}
          {subTotal && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={0.5}
            >
              <Typography variant="body2">
                {t("expressDelivery.subtotal")}:
              </Typography>
              <Typography variant="body2">
                {formatPrice(subTotal, { hideCurrencyCode: true })}
              </Typography>
            </Box>
          )}

          {/* Discount */}
          {discountTotal && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={0.5}
            >
              <Typography variant="body2">
                {t("expressDelivery.discount")}:
              </Typography>

              {!previewLoading && (
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.success.main }}
                >
                  {discountTotal?.amount > 0 ? "-" : ""}
                  {formatPrice(discountTotal, { hideCurrencyCode: true })}
                </Typography>
              )}
              {previewLoading && <PriceSkeleton />}
            </Box>
          )}

          {/* Delivery fee */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={0.5}
          >
            <Typography variant="body2">
              {t("expressDelivery.deliveryFee")}:
            </Typography>

            {!previewLoading && (
              <Typography variant="body2">
                {deliveryFeeTotal?.amount > 0 ? (
                  formatPrice(deliveryFeeTotal, {
                    hideCurrencyCode: true
                  })
                ) : (
                  <Typography
                    className="compact"
                    style={{ color: theme.palette.orange }}
                  >
                    {t("expressDelivery.deliveryFee.free")}
                  </Typography>
                )}
              </Typography>
            )}
            {previewLoading && <PriceSkeleton />}
          </Box>

          {/* Total tax */}
          {((taxTotal && taxTotal.amount > 0) || previewLoading) && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={0.5}
            >
              <Typography variant="body2">
                {t("expressDelivery.tax")}:
              </Typography>

              {!previewLoading && taxTotal && (
                <Typography variant="body2">
                  {formatPrice(taxTotal, { hideCurrencyCode: true })}
                </Typography>
              )}
              {previewLoading && <PriceSkeleton />}
            </Box>
          )}

          {/* Total */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
            paddingTop={2}
            borderTop={`2px solid ${theme.palette.grey[400]}`}
          >
            <Typography variant="h6">
              {`${t("expressDelivery.total")}: `}
            </Typography>

            <Box marginLeft={8}>
              {!previewLoading && total && (
                <Typography variant="h6">
                  {formatPrice(total, { hideCurrencyCode: true })}
                  {totalSuffix}
                </Typography>
              )}
              {previewLoading && <PriceSkeleton />}
            </Box>
          </Box>

          <Box mt={1}>
            <Typography variant="body2" color="textSecondary">
              Due to delivering dispensary at time of delivery.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
