import {
  Badge,
  Box,
  Hidden,
  Typography,
  useTheme,
  BoxProps
} from "@material-ui/core";
import React from "react";
import Imgix from "react-imgix";
import { formatMultilinesText } from "../../../helpers/markdown";
import { formatPrice } from "../../../helpers/price";
import useTranslate from "../../../hooks/use-translate";
import { PriceType } from "../../../types/airgraft";

export type ProductCardItemType = {
  title: string;
  description?: string;
  brand?: {
    name: string;
  };
  image?: string;
  variantDetail?: Queries.DatoCmsProductItemFragmentFragment["variants"][number]["variantDetail"];
  quantity: number;
  price: PriceType;
};

type Props = {
  products: ReadonlyArray<ProductCardItemType>;
} & BoxProps;

export default function ProductsCard({ products, ...props }: Props) {
  const theme = useTheme();
  const t = useTranslate();

  return (
    <Box
      borderLeft={`8px solid`}
      borderColor="primary.main"
      bgcolor="common.white"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
      borderRadius="4px"
      overflow="hidden"
      width="100%"
      marginBottom={1}
      display="flex"
      flexDirection="column"
      {...props}
    >
      {/* Products list */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        paddingX={{ xs: 1.5, sm: 2 }}
      >
        {products.map((product, i) => {
          const isLast = i === products.length - 1;
          return (
            <Box
              display="flex"
              key={i}
              borderBottom={
                !isLast ? `1px solid ${theme.palette.grey[300]}` : null
              }
              pt={2}
              pb={3}
            >
              {/* Image */}
              <Box
                width={{ xs: "75px", sm: "100px" }}
                height={{ xs: "75px", sm: "100px" }}
                marginRight={{ xs: 3, sm: 3 }}
                flexShrink={0}
              >
                <Badge
                  badgeContent={`${product.quantity}`}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  className="express-delivery large"
                >
                  <Box
                    width={{ xs: "75px", sm: "100px" }}
                    height={{ xs: "75px", sm: "100px" }}
                    borderRadius="3px"
                    bgcolor="grey.300"
                    overflow="hidden"
                  >
                    {product.image && (
                      <>
                        <Hidden smUp>
                          <Imgix
                            src={product.image}
                            width={75}
                            height={75}
                            disableSrcSet
                            imgixParams={{
                              ar: "1",
                              fit: "crop",
                              auto: "format,compress"
                            }}
                          />
                        </Hidden>
                        <Hidden xsDown>
                          <Imgix
                            src={product.image}
                            width={100}
                            height={100}
                            disableSrcSet
                            imgixParams={{
                              ar: "1",
                              fit: "crop",
                              auto: "format,compress"
                            }}
                          />
                        </Hidden>
                      </>
                    )}
                  </Box>
                </Badge>
              </Box>

              {/* Content */}
              <Box flex={1}>
                <Typography variant="h6">{product.title}</Typography>
                {product.brand?.name && (
                  <Typography variant="h6" color="textSecondary">
                    {t("by")} {product.brand?.name}
                  </Typography>
                )}

                <Box mt={0.5}>
                  {product.description && !product.variantDetail && (
                    <Typography
                      variant="caption"
                      dangerouslySetInnerHTML={{
                        __html: formatMultilinesText(product.description)
                      }}
                    />
                  )}

                  {product.variantDetail && (
                    <Typography component="span" variant="body2">
                      {product.variantDetail?.plantSpecies
                        ? t(
                            `plantSpecies.${product.variantDetail.plantSpecies}`
                          )
                        : ""}
                      ,&nbsp;
                      {product.variantDetail?.oilType
                        ? t(`oilType.${product.variantDetail.oilType}`)
                        : ""}
                    </Typography>
                  )}

                  {product.variantDetail && (
                    <Box display="flex" alignItems="center">
                      <Typography
                        component="span"
                        variant="body2"
                        color="textSecondary"
                      >
                        {`THC: ${product.variantDetail?.coaReport?.thcTotalPercentText}, ${product.variantDetail?.coaReport?.secondaryCannabinoidLabel}: ${product.variantDetail?.coaReport?.secondaryCannabinoidTotalPercentText}`}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Price */}
              {product.price && (
                <Box ml={0.5}>
                  <Typography variant="h6">
                    {formatPrice(
                      {
                        amount: product.price.amount * product.quantity,
                        currencyCode: product.price.currencyCode
                      },
                      {
                        hideCurrencyCode: true
                      }
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
