import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

/**
 * Loading placeholder for single-page-apps SSR rendering.
 * <NoSsr fallback={<NoSsrPlaceholder />}>
 */
export default function NoSsrPlaceholder() {
  return (
    <Box
      minHeight="calc(var(--initial-100vh) - var(--header-height))"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size="64px" disableShrink />
    </Box>
  );
}
