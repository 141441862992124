import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
  useTheme
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import moment from "moment-timezone";
import React from "react";
import useTranslate from "../../../../../hooks/use-translate";
import { OrderType } from "../../../../../types/airgraft-express";
import {
  getProviderLocalDatetime,
  getProviderLocalTimezone
} from "../../../../../helpers/express-delivery/provider";
import OrderStatusIcon from "./OrderStatusIcon";

const useStyles = makeStyles({
  "@global": {
    "@keyframes statusGradientAnimation": {
      "0%": {
        backgroundPosition: "left bottom"
      },
      "100%": {
        backgroundPosition: "right bottom"
      }
    }
  },
  statusGradient: {
    background:
      "-webkit-linear-gradient(left , #c5c1ba 0%, #ffbb00 20%, #c5c1ba 100%) repeat",
    backgroundSize: "50% 100%",
    animation: "statusGradientAnimation 0.75s linear infinite"
  }
});

type Props = {
  order: OrderType;
  isFetching?: boolean;
};

export default function OrderConfirmationOrderStatus({
  order,
  isFetching
}: Props) {
  const t = useTranslate();
  const theme = useTheme();
  const classNames = useStyles();

  const status = order.status;
  // Order is declined or cancelled?
  const orderIsCancelled =
    order.status === "DECLINED" || order.status === "CANCELLED";

  // Calculate status "index" from 0 to 4
  const statusOrder = [
    "PLACED",
    "ACCEPTED",
    "PACKED",
    "ON_THE_WAY",
    "COMPLETED"
  ];
  if (order.type === "pickup") {
    statusOrder[3] = "READY_PICKUP"; // Replace "ON_THE_WAY" with "READY_PICKUP"
  }
  const statusIndex = order.status ? statusOrder.indexOf(order.status) : null;

  // prettier-ignore
  const statusTranslationKey = `orderConfirmation.status.${order.status}${order.status === "COMPLETED" ? `.${order.type}` : ""}`;
  const statusTitle = order.status ? t(`${statusTranslationKey}.title`) : null;
  const statusDescription = order.status
    ? t(`${statusTranslationKey}.description`, {
        provider: order.provider?.publicName,
        phoneNumber: order.provider?.phoneNumber
      })
    : null;

  // Updated time status label "Updated: Today at 18:06 pm"
  let updatedTime = null;
  const updatedOriginTimeString =
    order.statusInfo?.updatedTime || order.createdDate;
  const updatedMomentTime = updatedOriginTimeString
    ? moment.tz(
        updatedOriginTimeString,
        moment.ISO_8601,
        getProviderLocalTimezone(order.provider)
      )
    : null;

  if (updatedMomentTime && updatedMomentTime.isValid()) {
    const { now } = getProviderLocalDatetime(order.provider);
    const isToday = updatedMomentTime.isSame(now, "day");
    const isThisYear = updatedMomentTime.isSame(now, "year");
    if (isToday) {
      updatedTime = updatedMomentTime.format("[today] [at] h:mm a");
    } else if (isThisYear) {
      updatedTime = updatedMomentTime.format("MMM Do, h:mm a");
    } else {
      updatedTime = updatedMomentTime.format("MMM Do YYYY, h:mm a");
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        mb={1}
      >
        {/* Line */}
        <Box
          height="3px"
          bgcolor="grey.500"
          position="absolute"
          top="50%"
          mt="-1px"
          left={theme.spacing(1)}
          right={theme.spacing(1)}
        />

        {!orderIsCancelled && (
          <>
            {/* Line: Orange part */}
            <Box
              // prettier-ignore
              width={`calc(${statusIndex / (statusOrder.length - 1) * 100}% - ${statusIndex === statusOrder.length - 1 ? theme.spacing(2) : 0}px)`}
              height="3px"
              bgcolor="primary.main"
              position="absolute"
              top="50%"
              mt="-1px"
              left={theme.spacing(1)}
              right={theme.spacing(1)}
            />

            {/* Line: Orange gradient part */}
            {statusIndex < statusOrder.length - 1 && (
              <Box
                // prettier-ignore
                width={`calc(${1 / (statusOrder.length - 1) * 100}% - ${statusIndex === statusOrder.length - 2 ? theme.spacing(2) : 0}px)`}
                height="3px"
                bgcolor="primary.main"
                position="absolute"
                top="50%"
                mt="-1px"
                // prettier-ignore
                left={`calc(${theme.spacing(1)}px + ${statusIndex /(statusOrder.length - 1) * 100}%)`}
                right={theme.spacing(1)}
                className={classNames.statusGradient}
              />
            )}
          </>
        )}

        {/* Status circles */}
        <OrderStatusIcon
          isActive={(status && statusIndex >= 0) || orderIsCancelled}
        />
        {status === "DECLINED" && <OrderStatusIcon isActive isError />}
        {status === "CANCELLED" && <OrderStatusIcon isActive isCancelled />}
        {!orderIsCancelled && <OrderStatusIcon isActive={statusIndex >= 1} />}
        <OrderStatusIcon isActive={statusIndex >= 2} />
        {(!order.type || order.type === "delivery") && (
          <OrderStatusIcon isActive={statusIndex >= 3} />
        )}
        {order.type === "pickup" && (
          <OrderStatusIcon isActive={statusIndex >= 2} />
        )}
        <OrderStatusIcon isActive={statusIndex >= 4} />
      </Box>

      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center">
          <Typography className="compact">
            Order #{order.providerOrderId}
          </Typography>
          <Box ml={1} mt="2px">
            {isFetching && <CircularProgress size="14px" color="secondary" />}
          </Box>
        </Box>

        {updatedTime && (
          <Typography
            className="compact"
            color="textSecondary"
            style={{ whiteSpace: "nowrap" }}
          >
            <Box display={{ xs: "none", sm: "inline" }}>
              {t("orderConfirmation.status.updated")}:{" "}
            </Box>
            {updatedTime}
          </Typography>
        )}
      </Box>

      <Box>
        {!isFetching && (
          <>
            {statusTitle && (
              <Typography variant="body1">{statusTitle}</Typography>
            )}
            {statusDescription && (
              <Box mt={0.5}>
                <Typography variant="body2" color="textSecondary">
                  {statusDescription}
                </Typography>
              </Box>
            )}
          </>
        )}
        {isFetching && (
          <>
            <Skeleton
              variant="text"
              animation="wave"
              width="120px"
              height="24px"
            />
            <Skeleton
              variant="text"
              animation="wave"
              width="240px"
              height="24px"
            />
          </>
        )}
      </Box>
    </Box>
  );
}
