import { stringify } from "query-string";
import { NETLIFY_FUNCTIONS_BASE_URL } from "../../services/netlify-functions";
import { getToken, isAuthenticated } from "../../services/user";
import { DeliveryAddressType } from "../../types/airgraft";
import { OrderItemType, OrderType } from "../../types/airgraft-express";

type RequestOrderType = {
  providerId: string;
  givenName: string;
  familyName: string;
  phoneNumber: string;
  email?: string;
  birthday?: string;
  deliveryAddress: DeliveryAddressType;
  cartItems: OrderItemType[];
  deliveryTime?: string;
  deliveryTimeEnd?: string;
};

async function requestOrder(
  orderOptions: RequestOrderType & { preview: boolean }
): Promise<OrderType> {
  const response = await fetch(
    `${NETLIFY_FUNCTIONS_BASE_URL}/delivery-order-request`,
    {
      method: "post",
      headers: {
        Authorization: getToken()
      },
      body: JSON.stringify(orderOptions)
    }
  );
  if (!response.ok) {
    const data = await response.json();
    const e = new Error(data?.message);
    // @ts-ignore
    e.errorReason = data.errorReason;
    throw e;
  }
  const data = await response.json();
  return data as OrderType;
}

/**
 * Prepare order to show products & price summary
 */
export async function previewOrder(
  orderOptions: RequestOrderType
): Promise<OrderType> {
  return requestOrder({ ...orderOptions, preview: true });
}

/**
 * Send order to provider for fullfilment
 */
export async function submitOrder(
  orderOptions: RequestOrderType
): Promise<OrderType> {
  return requestOrder({ ...orderOptions, preview: false });
}

/**
 * Get order details & status for order confirmation page
 */
export async function getOrderDetails(
  providerId: string,
  orderId: string
): Promise<OrderType> {
  const response = await fetch(
    `${NETLIFY_FUNCTIONS_BASE_URL}/delivery-order-details?providerId=${providerId}&orderId=${encodeURIComponent(
      orderId
    )}`,
    {
      method: "get",
      headers: {
        Authorization: getToken()
      }
    }
  );

  if (!response.ok) {
    throw new Error("Could not get order details");
  }
  const data = await response.json();
  return data as OrderType;
}

/**
 * Get current logged-in user order history
 */
export type OrderHistoryResultType = {
  orders: OrderType[] | null;
  lastPage: boolean;
};

export async function getUserOrderHistory(
  page?: number
): Promise<OrderHistoryResultType> {
  if (!isAuthenticated()) {
    return null;
  }

  const response = await fetch(
    `${NETLIFY_FUNCTIONS_BASE_URL}/delivery-order-history?${stringify({
      page: page || 0
    })}`,
    {
      method: "get",
      headers: {
        Authorization: getToken()
      }
    }
  );

  if (!response.ok) {
    return { orders: [], lastPage: true };
  }

  const data = await response.json();

  return data as OrderHistoryResultType;
}
