import React from "react";
import TitleGroup from "../../../../../components/title-group";
import { Grid, Box, Typography, useTheme, BoxProps } from "@material-ui/core";
import { formatMultilinesText } from "../../../../../helpers/markdown";
import { OrderType } from "../../../../../types/airgraft-express";
import useTranslate from "../../../../../hooks/use-translate";
import Skeleton from "@material-ui/lab/Skeleton";
import ProviderCard from "../../../ProviderCard";
import { getOrderDeliveryTimeLabels } from "../../../../../helpers/express-delivery/delivery-time";

export default function OrderHeader({
  order,
  ...props
}: { order: OrderType } & BoxProps) {
  const theme = useTheme();
  const t = useTranslate();

  const { line1: orderDeliveryTimeLine1, line2: orderDeliveryTimeLine2 } =
    getOrderDeliveryTimeLabels(order);

  return (
    <Box {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TitleGroup
            title={t(
              order?.type === "pickup"
                ? "orderConfirmation.myLocation"
                : "orderConfirmation.deliveredTo"
            )}
            marginBottom={{ xs: 2, md: 2 }}
          >
            {order && (
              <>
                <Typography variant="body2">
                  {`${order.deliveryAddress.addressOne}, ${order.deliveryAddress.city}, ${order.deliveryAddress.postalCode}`}
                </Typography>
                {order.deliveryAddress.addressTwo &&
                  order.deliveryAddress.addressTwo !== "" && (
                    <Typography variant="body2">
                      {order.deliveryAddress.addressTwo}
                    </Typography>
                  )}
                {order.deliveryAddress?.deliveryInstructions &&
                  order.deliveryAddress?.deliveryInstructions.trim() !==
                    "undefined" && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="stretch"
                      marginTop={1}
                    >
                      <Typography variant="body2" color="textSecondary">
                        {t("orderConfirmation.deliveryInstructions")}
                      </Typography>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: formatMultilinesText(
                            order.deliveryAddress.deliveryInstructions
                          )
                        }}
                        style={{
                          maxHeight: theme.spacing(18),
                          overflowY: "auto"
                        }}
                      />
                    </Box>
                  )}
              </>
            )}
            {!order && (
              <>
                <Skeleton width="100%" variant="text" animation="wave" />
                <Skeleton width="55%" variant="text" animation="wave" />
                <Skeleton width="65%" variant="text" animation="wave" />
              </>
            )}
          </TitleGroup>
        </Grid>

        {order?.deliveryTime && (
          <Grid item xs={12} md={12}>
            <TitleGroup
              title={t(`orderConfirmation.${order?.type || "delivery"}Time`)}
              marginBottom={{ xs: 2, md: 2 }}
            >
              {order && (
                <>
                  {orderDeliveryTimeLine1 && (
                    <Typography variant="body2">
                      {orderDeliveryTimeLine1}
                    </Typography>
                  )}
                  {orderDeliveryTimeLine2 && (
                    <Typography variant="body2">
                      {orderDeliveryTimeLine2}
                    </Typography>
                  )}
                </>
              )}
              {!order && (
                <>
                  <Skeleton width="100%" variant="text" animation="wave" />
                  <Skeleton width="55%" variant="text" animation="wave" />
                </>
              )}
            </TitleGroup>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <TitleGroup
            title={t(
              `orderConfirmation.${
                order?.type === "pickup" ? "dispensary" : "deliveredBy"
              }`
            )}
            marginBottom={{ xs: 2, md: 0 }}
          >
            {order && <ProviderCard provider={order.provider} />}
            {!order && (
              <>
                <Skeleton width="100%" variant="text" animation="wave" />
                <Skeleton width="65%" variant="text" animation="wave" />
                <Skeleton width="35%" variant="text" animation="wave" />
              </>
            )}
          </TitleGroup>
        </Grid>
      </Grid>
    </Box>
  );
}
