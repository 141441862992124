import { NoSsr } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import NoSsrPlaceholder from "../../components/no-ssr-placeholder";
import Seo from "../../components/seo";
import OrderConfirmation from "../../containers/express-delivery/OrderConfirmation";

// Route /delivery/order/:providerId:/:orderId with params is mapped inside gatsby-node.js to this page
type Props = {
  params: {
    providerId: string;
    orderId: string;
  };
  data: Queries.OrderConfirmationPageDataQuery;
};

export default function DeliveryOrderConfirmationPage(props: Props) {
  return (
    <>
      <Seo
        seo={props.data.datoCmsWebPage?.seo}
        seoMetaTags={props.data.datoCmsWebPage?.seoMetaTags}
      />
      <NoSsr fallback={<NoSsrPlaceholder />}>
        <OrderConfirmation
          allProducts={props.data.allDatoCmsProduct.nodes}
          providerId={props.params.providerId}
          orderId={props.params.orderId}
        />
      </NoSsr>
    </>
  );
}

export const query = graphql`
  query OrderConfirmationPageData($locale: String!) {
    datoCmsWebPage(path: { eq: "delivery/order" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
    allDatoCmsProduct(filter: { locale: { eq: $locale } }) {
      nodes {
        ...DatoCmsProductItemFragment
      }
    }
  }
`;
