import moment from "moment-timezone";

/**
 * Generate human readable date for order delivery time
 * Ex: Today: June 16th 9:00 pm - 10:00 pm
 * This utility is used for the AGX Order Confirmation page, and the Order confirmation email
 * Note: Do not convert this file to Typescript else it cannot be imported inside lamda functions/
 * @param {OrderType} order
 */
export function getOrderDeliveryTimeLabels(order) {
  // prettier-ignore
  const orderDeliveryTime = order ? moment.tz(order.deliveryTime, moment.ISO_8601, order.provider.timezone): null;
  let orderDeliveryTimeEnd = order
    ? orderDeliveryTime.clone().add(1, "hours")
    : null;
  if (order?.deliveryTimeEnd) {
    orderDeliveryTimeEnd = moment.tz(
      order.deliveryTimeEnd,
      moment.ISO_8601,
      order.provider.timezone
    );
  }

  // prettier-ignore
  const isToday = orderDeliveryTime && orderDeliveryTime.isSame(moment(), "d");
  // prettier-ignore
  const isTomorow = orderDeliveryTime && orderDeliveryTime.isSame(moment().clone().add(1, "days"), "d");
  let line1Prefix = orderDeliveryTime ? orderDeliveryTime.format("dddd") : "";
  if (isToday) {
    line1Prefix = `Today: `;
  } else if (isTomorow) {
    line1Prefix = `Tomorow: `;
  }
  // prettier-ignore
  const line1 = orderDeliveryTime ? `${line1Prefix} ${orderDeliveryTime.format("MMMM")} ${orderDeliveryTime.format("Do")}` : null;
  // prettier-ignore
  let line2 = orderDeliveryTime ? `${orderDeliveryTime.format("h:mm a")} - ${orderDeliveryTimeEnd.format("h:mm a")}`: null;

  // Show "Open - Close" times for pickup order as line 2
  if (order && order.type === "pickup" && order.deliveryTime) {
    const weekday = moment
      .weekdaysShort(orderDeliveryTime.weekday())
      .toLowerCase();
    const weekdayConfig = order.provider.pickupTimes[weekday];
    const open = moment.tz(
      weekdayConfig.open,
      "HH:mm",
      order.provider.timezone
    );
    const close = moment.tz(
      weekdayConfig.close,
      "HH:mm",
      order.provider.timezone
    );
    line2 = `${open.format("h:mm a")} - ${close.format("h:mm a")}`;
  }

  return {
    line1,
    line2
  };
}
