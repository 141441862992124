import React from "react";
import {
  Box,
  BoxProps,
  Collapse,
  IconButton,
  Typography,
  useTheme
} from "@material-ui/core";
import { useToggle } from "react-use";
import ArrowDown from "../icons/arrow-down";

type Props = {
  beforeTitle?: React.ReactNode;
  title: string;
  subtitle?: string | React.ReactNode;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
} & BoxProps;

export default function TitleGroup({
  beforeTitle,
  title,
  subtitle,
  children,
  collapsible,
  defaultCollapsed,
  style,
  ...props
}: Props) {
  const theme = useTheme();
  const [expanded, toggleExpanded] = useToggle(
    defaultCollapsed !== undefined ? !defaultCollapsed : true
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      style={style}
      {...props}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={2}
        paddingBottom={{ xs: 0, md: 1 }}
        borderBottom={`2px solid ${theme.palette.grey[400]}`}
        alignSelf="stretch"
        style={{ cursor: collapsible ? "pointer" : undefined }}
        onClick={collapsible ? toggleExpanded : undefined}
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            {beforeTitle && <Box marginRight={1}>{beforeTitle}</Box>}
            <Typography
              variant="h6"
              component="div"
              style={{ paddingBottom: theme.spacing(1) }}
            >
              {title}
            </Typography>
          </Box>

          {subtitle && (
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              style={{ marginTop: theme.spacing(-0.5) }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>

        {collapsible && (
          <IconButton style={{ padding: "6px" }}>
            <ArrowDown
              style={{
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)"
              }}
            />
          </IconButton>
        )}
      </Box>

      {!collapsible && children}
      {collapsible && (
        <Collapse in={expanded} style={{ width: "100%" }}>
          {children}
        </Collapse>
      )}
    </Box>
  );
}
