import React from "react";
import { Box, BoxProps, Typography } from "@material-ui/core";

import { OrderType } from "../../../../../types/airgraft-express";
import useTranslate from "../../../../../hooks/use-translate";

export default function OrderInstructions({
  order,
  ...props
}: BoxProps & { order: OrderType }) {
  const t = useTranslate();

  if (!order?.provider[`${order.type}Instructions`]) {
    return null;
  }

  return (
    <Box
      paddingX={3}
      paddingY={2}
      borderRadius="8px"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
      bgcolor="common.white"
      {...props}
    >
      <Box mb={1}>
        <Typography>
          {t(`orderConfirmation.instructions.${order.type}`, {
            providerName: order.provider?.publicName || ""
          })}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: order.provider[`${order.type}Instructions`]
        }}
      />
    </Box>
  );
}
