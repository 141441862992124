import React from "react";
import { DeliveryProviderType } from "../../../types/airgraft-express";
import { Box, useTheme, Typography } from "@material-ui/core";
import useTranslate from "../../../hooks/use-translate";

type Props = {
  provider: DeliveryProviderType;
};

export default function ExpressDeliveryProviderCard({ provider }: Props) {
  const theme = useTheme();
  const t = useTranslate();

  return (
    <Box display="flex" alignItems="center">
      {/* Provider logo */}
      {provider.logo && (
        <Box width={theme.spacing(6)} height="100%" marginRight={2}>
          <img
            style={{ width: "100%", height: "auto" }}
            src={provider.logo.url}
          />
        </Box>
      )}

      <Box flex="1">
        <Typography variant="body1">{provider.publicName}</Typography>

        {provider.license && (
          <Typography variant="body2" color="textSecondary" component="div">
            {`${t("expressDelivery.licenseId")}: `}
            {provider.license}
          </Typography>
        )}

        {provider.phoneNumber && (
          <Typography
            variant="body2"
            color="textSecondary"
            component="a"
            href={`tel:${provider.phoneNumber}`}
            style={{ display: "block" }}
          >
            {t("expressDelivery.phone", {
              phoneNumber: provider.phoneNumber
            })}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
