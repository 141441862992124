import React from "react";
import { Box, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckmarkIcon from "../../../../../components/icons/checkmark";

type Props = {
  isActive: boolean;
  isError?: boolean;
  isCancelled?: boolean;
};

export default function OrderStatusIcon({
  isActive,
  isError,
  isCancelled
}: Props) {
  const theme = useTheme();
  let circleColor = "grey.200";
  if (isError) {
    circleColor = "error.main";
  } else if (isCancelled) {
    circleColor = "error.main";
  } else if (isActive) {
    circleColor = "primary.main";
  }

  const emptyStyle = {
    height: `1.4rem`,
    width: `1.4rem`,
    border: `2px solid ${theme.palette.grey[500]}`
  };

  const activeStyle = {
    height: `2.6rem`,
    width: `2.6rem`
  };

  return (
    <Box
      borderRadius="50%"
      flex="0 0 auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={circleColor}
      zIndex={1}
      style={isActive ? activeStyle : emptyStyle}
    >
      {isError || isCancelled ? (
        <CloseIcon
          style={{ fontSize: "1.8rem", fill: theme.palette.common.white }}
        />
      ) : (
        isActive && <CheckmarkIcon />
      )}
    </Box>
  );
}
