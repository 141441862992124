import React from "react";
import { Box } from "@material-ui/core";
import { SpacingProps } from "@material-ui/system";
import Skeleton from "@material-ui/lab/Skeleton";

type Props = SpacingProps;

export default function ProductsCardSkeleton(props: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="180px"
      paddingBottom={2}
      {...props}
    >
      <Box display="flex" alignItems="stretch" width="100%">
        <Box
          width={{ xs: "60px", sm: "100px" }}
          height={{ xs: "60px", sm: "100px" }}
          marginRight={{ xs: 2, md: 3 }}
          borderRadius="3px"
          overflow="hidden"
        >
          <Skeleton
            variant="rect"
            width="100%"
            height="100%"
            animation="wave"
          />
        </Box>
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Box>
            <Skeleton variant="text" width="90%" animation="wave" />
            <Skeleton variant="text" width="70%" animation="wave" />
          </Box>
          <Box mt={{ xs: 0, sm: 2 }}>
            <Skeleton variant="text" width="35%" animation="wave" />
            <Skeleton variant="text" width="25%" animation="wave" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
