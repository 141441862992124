import { Box, Button, Container, styled, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { useQuery } from "react-query";
import LocalizedLink from "../../../components/localized-link";
import TitleGroup from "../../../components/title-group";
import { getOrderDetails } from "../../../helpers/express-delivery/order";
import useTranslate from "../../../hooks/use-translate";
import { ProductListItemType } from "../../../types/airgraft";
import OrderSummary from "../OrderSummary";
import OrderConfirmationOrderStatus from "./components/OrderConfirmationOrderStatus";
import OrderHeader from "./components/OrderHeader";
import OrderInstructions from "./components/OrderInstructions";
import ProductsCardSkeleton from "./components/ProductsCardSkeleton";

const OrderConfirmationDetailsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(16),
  [theme.breakpoints.up("sm")]: {
    background: theme.palette.grey[100],
    borderRadius: "8px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(6, 8)
  },
  [theme.breakpoints.down("xs")]: {}
}));

type Props = {
  providerId: string;
  orderId: string;
  allProducts: ReadonlyArray<ProductListItemType>;
};

export default function ExpressDeliveryOrderConfirmation(props: Props) {
  const t = useTranslate();

  /**
   * Fetch order details
   */
  const {
    data: order,
    isFetching,
    error: orderError
  } = useQuery(
    ["order", props.orderId],
    () => getOrderDetails(props.providerId, props.orderId),
    {
      staleTime: 1000 * 30, // 30 seconds
      refetchOnWindowFocus: true,
      retry: 3
    }
  );

  const orderIsCancelled =
    order?.status === "DECLINED" || order?.status === "CANCELLED";

  return (
    <Container maxWidth="md" style={{ maxWidth: "808px" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        marginTop={{ xs: 3, md: 8 }}
        marginBottom={4}
      >
        {/* Header*/}
        {!orderError && (
          <Box display="flex" flexDirection="column" minHeight={"136px"}>
            <>
              {order && (
                <Box>
                  <Box marginBottom={3}>
                    <Typography variant="h5" component="div">
                      {t("orderConfirmation.title.line1", {
                        providerName: order.provider.publicName
                      })}
                    </Typography>
                    <Typography variant="h5" component="div">
                      {t("orderConfirmation.title.line2", {
                        providerOrderId: order.providerOrderId
                      })}
                    </Typography>
                  </Box>
                  <Typography variant="body1" component="div">
                    {t("orderConfirmation.description", {
                      phoneNumber: order.provider.phoneNumber,
                      providerName: order.provider.publicName
                    })}
                  </Typography>
                </Box>
              )}
              {!order && (
                <>
                  <Box mb={{ xs: 2, sm: 4 }}>
                    <Skeleton
                      width="80%"
                      height="32px"
                      variant="text"
                      animation="wave"
                    />
                    <Skeleton
                      width="60%"
                      height="32px"
                      variant="text"
                      animation="wave"
                    />
                  </Box>
                  <Skeleton
                    width="90%"
                    height="24px"
                    variant="text"
                    animation="wave"
                  />
                  <Skeleton
                    width="40%"
                    height="24px"
                    variant="text"
                    animation="wave"
                  />
                </>
              )}
            </>
          </Box>
        )}
        {/* Order status */}
        {order && (
          <Box marginTop={4}>
            <OrderConfirmationOrderStatus
              order={order}
              isFetching={isFetching}
            />
          </Box>
        )}
      </Box>

      {!orderIsCancelled && (
        <>
          {/* Order instructions */}
          <OrderInstructions order={order} marginBottom={4} />

          {/* Order products & prices */}
          <OrderConfirmationDetailsContainer>
            {/* Error */}
            {orderError && (
              <Alert severity="error">{t("orderConfirmation.error")}</Alert>
            )}

            {/* Order */}
            {!orderError && (
              <>
                {/* 3 columns header grid */}
                <OrderHeader order={order} marginBottom={{ xs: 2, md: 3 }} />

                <TitleGroup title={t("orderConfirmation.yourOrder")}>
                  {order && (
                    <OrderSummary
                      productCardItems={order.items.map(orderItem => {
                        const product = props.allProducts.find(p =>
                          p.variants.find(v => v.originalId === orderItem.id)
                        );
                        const productVariant = product?.variants.find(
                          v => v.originalId === orderItem.id
                        );

                        if (!productVariant) {
                          return {
                            title: orderItem.metaProps?.title || "Product item",
                            description: "Product information unavailable",
                            brand: {
                              name: orderItem.metaProps?.brand
                            },
                            quantity: orderItem.quantity,
                            price: orderItem.price
                          };
                        }
                        return {
                          ...productVariant,
                          quantity: orderItem.quantity,
                          price: orderItem.price,
                          image: productVariant.featuredImage.url
                        };
                      })}
                      discountTotal={order?.discountTotal}
                      deliveryFeeTotal={order?.deliveryFeeTotal}
                      taxTotal={order?.taxTotal}
                      subTotal={order?.subTotal}
                      total={order?.total}
                    />
                  )}
                  {/* Loading */}
                  {!order && <ProductsCardSkeleton />}
                </TitleGroup>

                {/* Back,Next buttons */}
                <Box
                  marginTop={{ xs: 10, md: 10 }}
                  marginBottom={{ xs: 4, md: 0 }}
                  display="flex"
                  flexDirection="column"
                >
                  <Box mb={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      component={LocalizedLink}
                      to={"/shop"}
                    >
                      {t("orderConfirmation.goToShop")}
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    component={LocalizedLink}
                    to={"/user/orders"}
                  >
                    {t("orderConfirmation.seeProfileOrders")}
                  </Button>
                </Box>
              </>
            )}
          </OrderConfirmationDetailsContainer>
        </>
      )}
    </Container>
  );
}
